
import { Link } from "react-router-dom";
import biology from "../../assets/images/category/icon/biology.png"
import chemistry from "../../assets/images/category/icon/chemistry.png"
import physics from "../../assets/images/category/icon/physics.png"
import math from "../../assets/images/category/icon/Math.png"
import computer from "../../assets/images/category/icon/computer.png"
import english from "../../assets/images/category/icon/English.png"
import ps from "../../assets/images/category/icon/ps.png"
import Is from "../../assets/images/category/icon/is.png"
import ur from "../../assets/images/category/icon/urdu.png"



const subTitle = "Popular Category";
const title = "Popular Category For Learn";
const btnText = "Browse All Categories";


const categoryList = [
    {
        imgUrl: biology,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Chemistry',
        count: '04 Course',
    },
    {
        imgUrl: chemistry,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Chemistry',
        count: '27 Course',
    },
    {
        imgUrl: physics,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Physics',
        count: '28 Course',
    },
    {
        imgUrl: math,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Maths',
        count: '78 Course',
    },
    {
        imgUrl: computer,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Computer Science',
        count: '38 Course',
    },
    {
        imgUrl: english,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'English',
        count: '38 Course',
    },

    {
        imgUrl: ps,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Pak Studies',
        count: '38 Course',
    },
    {
        imgUrl: Is,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Islamiat',
        count: '38 Course',
    },

    {
        imgUrl: ur,
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'Urdu',
        count: '38 Course',
    },
]


const Category = () => {
    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Category;