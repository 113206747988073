import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import logo from "../../assets/images/logo/EduBookweblogo.png"
import '../../assets/css/header.css'

const phoneNumber = "+800-123-4567 6587";
const address = "Beverley, New York 224 USA";


let socialList = [
    {
        iconName: 'icofont-facebook-messenger',
        siteLink: '#',
    },
    {
        iconName: 'icofont-twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-vimeo',
        siteLink: '#',
    },
    {
        iconName: 'icofont-skype',
        siteLink: '#',
    },
    {
        iconName: 'icofont-rss-feed',
        siteLink: '#',
    },
]

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    return (
        <header className={`header-section ${headerFiexd ? "header-fixed fadeInUp  top-0" : ""}`}>
            {/* <div className={`header-top ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <ul className="lab-ul left">
                            <li><i className="icofont-ui-call"></i> <span>{phoneNumber}</span></li>
                            <li><i className="icofont-location-pin"></i> {address}</li>
                        </ul>
                        <ul className="lab-ul social-icons d-flex align-items-center">
                            <li><p>Find us on : </p></li>
                            {socialList.map((val, i) => (
                                <li key={i}><a href={val.siteLink}><i className={val.iconName}></i></a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/"><img src={logo} alt="logo" /></Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                <li><NavLink to="/">Home</NavLink></li>
                                    {/* <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Text Books</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/">Course</NavLink></li>
                                            <li><NavLink to="/">Course Details</NavLink></li>
                                            <li><NavLink to="/">Course View</NavLink></li>
        
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Past Papers</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/">Blog Grid</NavLink></li>
                                            <li><NavLink to="/">Blog Style 2</NavLink></li>
                                            <li><NavLink to="/">Blog Style 3</NavLink></li>
                                            <li><NavLink to="/">Blog Single</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Ai Tutor</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/">About</NavLink></li>
                                            <li><NavLink to="/">Team</NavLink></li>
                                            <li><NavLink to="/">Instructor</NavLink></li>
                                            <li><NavLink to="/">Shop Page</NavLink></li>
                                            <li><NavLink to="/">Shop Details Page</NavLink></li>
                                            <li><NavLink to="/">Shop Cart Page</NavLink></li>
                                            <li><NavLink to="/">Search Page</NavLink></li>
                                            <li><NavLink to="/">Search None</NavLink></li>
                                            <li><NavLink to="/">404</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/">Pricing</NavLink></li>
                                    <li><NavLink to="/">Contact</NavLink></li> */}
                                </ul>
                            </div>
                            
                            <ScrollLink to="about-section" smooth={true} duration={1000} className="login" ><i class="bi bi-award-fill"></i><span>Benifits</span> </ScrollLink>
                            <Link to="/wishlist" className="signup"><i className="icofont-users"></i> <span>WishList</span> </Link>

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default Header;