import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
import Blog from "../component/section/blog";
import Category from "../component/section/category";
import Course from "../component/section/course";
import Instructor from "../component/section/instructor";
import Sponsor from "../component/section/sponsor";
import Student from "../component/section/student";
import Grade from "../component/section/Grade";
import StudyPlan from "../component/section/StudyPlan";
import Faq from "../component/section/Faq";
import Pricingplan from "../component/section/Pricingplan";
import img1 from "../assets/images/studyplan/step1.png";
import img2 from "../assets/images/studyplan/step2.png";
import img3 from "../assets/images/studyplan/step3.png";
import img4 from "../assets/images/studyplan/step4.png";
import aboutimg from "../assets/images/about/aboutimg.png"
import aboutgr from "../assets/images/about/girl.png"

const aiTutor = {
    leftimg:aboutimg,
    subtitle:'',
    title: "Ai Tutor",
    desc: `Distinctively provide access multifuntional to users whereas transparent processes sometimes
incentivize efficient functionalities rather than extensible architecture communicate
leveraged services and cross-platform. Distinctively provide access multifuntional to users whereas transparent processes sometimes
incentivize efficient functionalities rather than extensible architecture communicate
leveraged services and cross-platform.`,
    aboutList: [
        {
            imgUrl: 'assets/images/about/icon/01.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Voice to Text & Text to Voice',
            desc: 'Students can choose a book.',
        },
        {
            imgUrl: 'assets/images/about/icon/02.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Essay Writing',
            desc: 'ChatGPT can generate a personal study plan based on the book chapters.',
        },
        {
            imgUrl: 'assets/images/about/icon/03.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'AI Assistant',
            desc: 'ChatGPT will analyze past papers and predict which chapters cover a higher percentage of the exam.',
        },
    ]
};

const aboutdata = {
    leftimg:aboutgr,
    subtitle:'About Our AI Tutor',
    title: "About Us",
    desc: `Distinctively provide acces mutfuncto users whereas transparent proceses somes
ncentivize eficient functionalities rather than extensible archtectur communicate
leveraged services and cross-platform.`,
    aboutList: [
        {
            imgUrl: 'assets/images/about/icon/01.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Skilled Instructors',
            desc: `Distinctively provide acces mutfuncto users whereas
communicate leveraged services`,
        },
        {
            imgUrl: 'assets/images/about/icon/02.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Study Plans',
            desc: `Distinctively provide acces mutfuncto users whereas
communicate leveraged services`,
        },
        {
            imgUrl: 'assets/images/about/icon/03.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'AI Support',
            desc: `Distinctively provide acces mutfuncto users whereas
communicate leveraged services`,
        },
    ]
};


const aiNotes = {
    leftimg:aboutimg,
    subtitle:'',
    title: "Smart Notes",
    desc: `Distinctively provide acces mutfuncto users whereas transparent proceses somes
ncentivize eficient functionalities rather than extensible archtectur communicate
leveraged services and cross-platform.`,
    aboutList: [
        {
            imgUrl: 'assets/images/about/icon/01.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Create Notes',
            desc: 'Students can choose a book.',
        },
        {
            imgUrl: 'assets/images/about/icon/02.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Access Notes',
            desc: 'ChatGPT can generate a personal study plan based on the book chapters.',
        },
        {
            imgUrl: 'assets/images/about/icon/03.jpg',
            imgAlt: 'about icon rajibraj91 rajibraj',
            title: 'Share Notes',
            desc: 'ChatGPT will analyze past papers and predict which chapters cover a higher percentage of the exam.',
        },
    ]
};


const studyPlandata = {
    title: "Personal Study Plan",
    desc: ` No need to alter your assignments. Grade paper-based, digital, and
                            assignments in half the time`,

    stepdata: [
        {
            id: "1",
            imgurl: img1,
            ttile: "Choose Book",
            dess: "Students can choose a book."
        },
        {
            id: "2",
            imgurl: img2,
            ttile: "Your Study Plan",
            dess: `AI Tutor will generate a personal study plan based on the book chapters.`
        },

        {
            id: "3",
            imgurl: img3,
            ttile: "Get AI Analytics",
            dess: `AI Tutor will analyze past papers and predict which chapters cover a higher percentage of the exam.`
        },

        {
            id: "4",
            imgurl: img4,
            ttile: "Share & Export Plan",
            dess: `Send plan to students with a click or export them to your own planbook.`
        },

    ]
}

const studyPlandata1 = {
    title: "Submit Lectures & Queriesn",
    desc: ` No need to alter your assignments. Grade paper-based, digital, and
 assignments in half the time.`,

    stepdata: [
        {
            id: "1",
            imgurl: img1,
            ttile: "Upload Sheet",
            dess: "Student can upload lecture and question sheets."
        },
        {
            id: "2",
            imgurl: img2,
            ttile: "Sheet Submissions",
            dess: `Submit lecture OR question sheet to AI Tutor.`
        },

        {
            id: "3",
            imgurl: img3,
            ttile: "AI Generated Key points",
            dess: `Get AI generated Answers & Keypoints of Lectures `
        },

        {
            id: "4",
            imgurl: img4,
            ttile: "Share & Export Plan",
            dess: `Send plan to students with a click or export them to your own planbook.`
        },

    ]
}



const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />

            <Category />
            <div id="about-section">

            <About aiTutor={aiTutor}  />
            </div>
            {/* <Grade /> */}
            <StudyPlan planData={studyPlandata} />
           
            <About aiTutor={aiNotes}   />
           
        

            <StudyPlan planData={studyPlandata1} />

            {/* <About  aiTutor={aboutdata}/>
            <Pricingplan /> */}
            {/* <Blog /> */}

            {/* <Faq /> */}

            <Footer />
        </Fragment>
    );
}

export default Home;