
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { Slide,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import About from "./component/section/about";

import Home from "./page/home";


import Whishlist from "./page/WishList";



function App() {
	return (
		<>
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="wishlist" element={<Whishlist />} />
			    <Route path="benifits" element={<About/>}/>
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
		<ToastContainer/>
		</>
	);
}

export default App;
