import React from 'react'
import "../../assets/css/studyPlan.css"
import Avatar from 'react-avatar'
import img1 from "../../assets/images/studyplan/step1.png";
import img2 from "../../assets/images/studyplan/step2.png";
import img3 from "../../assets/images/studyplan/step3.png";
import img4 from "../../assets/images/studyplan/step4.png";


const stepdata = [
    {
        id: "1",
        imgurl: img1,
        ttile: "Choose Book",
        dess: "Students can choose a book."
    },
    {
        id: "2",
        imgurl: img2,
        ttile: "Your Study Plan",
        dess: `AI Tutor will generate a personal study plan based on the book chapters.`
    },

    {
        id: "3",
        imgurl: img3,
        ttile: "Get AI Analytics",
        dess: `AI Tutor will analyze past papers and predict which chapters cover a higher percentage of the exam.`
    },

    {
        id: "4",
        imgurl: img4,
        ttile: "Share & Export Plan",
        dess: `Send plan to students with a click or export them to your own planbook.`
    },

]



const StudyPlan = (props) => {
    const {planData}=props

    return (
        <div className='study-plan-section   padding-tb   '>
            <div className='container'>

                <div className='d-flex flex-column gap-3'>
                    <div className='d-flex flex-column gap-2 align-items-center gap-2 w-lg-50 mx-auto'>
                        <h2 className='cl-orange'>{planData?.title}</h2>
                        <p className='text-center'>
                           {planData?.desc}
                        </p>


                    </div>

                    <div className='row g-2 justify-content-lg-center row-cols-lg-4     row-cols-1   mx-auto'>


                        {
                            planData?.stepdata.map((item, index) => (
                                <div className='col' key={index}>
                                    <div className='d-flex flex-column flex-sm-row   flex-lg-column gap-3 justify-content-lg-center align-items-lg-center'>

                                        <div className='avatar-wrap position-relative'>

                                            <Avatar src={item.imgurl} size="178" round={true} />

                                            <div className='count-badge'>

                                                <Avatar  value={item.id} size="40" round={true} className='avatar-badg' />
                                            </div>

                                        </div>

                                        <div className='d-flex flex-column  justify-content-center align-items-lg-center gap-1 w-100'>
                                            <span className='subtitle font-medium cl-orange'>
                                                {item.ttile}
                                            </span>
                                            <p className='text-lg-center'>
                                                {item.dess}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </div>

            </div>


        </div>
    )
}

export default StudyPlan
