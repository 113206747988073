
import aboutimg from "../../assets/images/about/aboutimg.png";


const title = "Ai Tutor";
const desc = `Distinctively provide acces mutfuncto users whereas transparent proceses somes
ncentivize eficient functionalities rather than extensible archtectur communicate
leveraged services and cross-platform.Distinctively provide acces mutfuncto users whereas transparent proceses somes
ncentivize eficient functionalities rather than extensible archtectur communicate
leveraged services and cross-platform.`;



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Voice to Text & Text to Voice',
        desc: 'Students can choose a book.',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Essay Writing',
        desc: 'ChatGPT can generate a personal study plan based on the book chapters.',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'AI Assistant',
        desc: 'ChatGPT will analyze past papers and predict which chapters cover a higher percentage of the exam.',
    },
]




const About = (props) => {
    const { aiTutor } = props;
    console.log(aiTutor, "xwdw")

    return (
        <div className="about-section padding-tb">
            <div className="container">
                <div className="row justify-content-center row-cols-xl-2 row-cols-1  flex-row-reverse">
                    <div className="col">
                        <div className="about-right ">
                            <div className="section-header">
                                {aiTutor?.subtitle !== '' ? (
                                    <span className="subtitle">{aiTutor?.subtitle}</span>
                                ) : ''}


                                <h2 className="title">{aiTutor?.title}</h2>
                                <p>{aiTutor?.desc}</p>
                            </div>
                            <div className="section-wrapper">
                                <ul className="lab-ul">
                                    {aiTutor?.aboutList.map((val, i) => (
                                        <li key={i}>
                                            <div className="sr-left">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="sr-right">
                                                <h5>{val.title}</h5>
                                                <p>{val.desc}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb w-100">
                                <img src={aiTutor?.leftimg} alt="about" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;