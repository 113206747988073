import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";



const title = "Add to WishList";
const socialTitle = "R";
const btnText = "Add to WishList";


let socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


const Whishlist = () => {


    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        schoolName: '',
        class:''


      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async(e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        const data = new FormData();
        data.append('fullName', formData.fullName);
        data.append('email', formData.email);
        data.append('phoneNumber', formData.phoneNumber);
        data.append('schoolName',formData.schoolName);
        data.append('class',formData.class)
       // your URL.
    
        const Sheet_Url="https://script.google.com/macros/s/AKfycbxERuPChpoJRhCT3lc6hAITczCcNMVG0ddHS-SK6qLumjcGibFt_pfZ8Wd1Gdsz-Xz-/exec"
        try {
          await fetch(Sheet_Url, {
            method: 'POST',
            body: data,
            muteHttpExceptions: true,
          });
          toast.success('Thank You !  We will contact You as soon as possible.')
        
          
    
          setFormData({
            fullName: '',
            email: '',
            phoneNumber: '',
            schoolName:'',
            class:''
          });
          
        } catch (error) {
          console.log(error);
          toast.error('Failed to save data. Please try again.');
        }
      };
    return (
        <Fragment>
            <Header />
            {/* <PageHeader title={'WishList'} curPage={'Wishlist'} /> */}
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                  type="text"
                                  id="fullName"
                                  name="fullName"
                                  value={formData.fullName}
                                  onChange={handleChange}
                    
                                  placeholder="User Name"
                                  required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                      type="tel"
                                      id="phoneNumber"
                                      name="phoneNumber"
                                      value={formData.phoneNumber}
                                      onChange={handleChange}
                                    placeholder="Contact No"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                      type="text"
                                      id="schoolName"
                                      name="schoolName"
                                      value={formData.schoolName}
                                      onChange={handleChange}
                                    placeholder="School / College name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                      type="text"
                                      id="class"
                                      name="class"
                                      value={formData.class}
                                      onChange={handleChange}
                                    placeholder="Class"
                                    required
                                />
                            </div>
                        
                            <div className="form-group">
                                <button className="lab-btn"  type="submit"><span>{btnText}</span></button>
                            </div>
                        </form>
                  
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default Whishlist;